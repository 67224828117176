<template>
    <div class="tgcss-w-full tgcss-pb-2 tgcss-px-1 font-IRANSans">

        <div class="tgcss-w-full tgcss-flex tgcss-items-center tgcss-mb-2">
            <span class="tgcss-text-sm tgcss-font-bold grow">{{title}}</span> 
            <router-link :to="{ name: 'ApplicationNews' , params: { category_id: cat, }}" class="tgcss-opacity-70 tgcss-text-xs !tgcss-no-underline !tgcss-text-gray-800">مشاهده‌همه</router-link>                
        </div>
        <slot v-if="loading">
            <div class="tgju-widget-content full-padding">
                <div class="text-center p-2">
                    <div class="widget-loading"></div>
                </div>
            </div>
        </slot>
        <div v-if="!loading && slider.items" class="tgcss-flex tgcss-overflow-x-auto tgcss-flex-nowrap tgcss-py-2 tgcss-pb-3">
            <router-link v-for="item in slider.items.data" :key="item.id" :to="{ name: 'ApplicationNewsContent' , params: { id: item.id, }, query: { subject: 'tgju-news-' + item.id }}" class="tgcss-cursor-pointer tgcss-rounded-full tgcss-overflow-hidden tgcss-w-fit tgcss-min-w-fit tgcss-border-2 tgcss-border-gray-200 tgcss-ml-2 tgcss-p-[2px] "
               :class="{ '!tgcss-border-[#f39327]' : isUpdatedLessThan24HoursAgo(item.updated_at) }"
            >
                <div class="tgcss-border tgcss-flex tgcss-items-center tgcss-justify-center tgcss-border-gray-300 tgcss-rounded-full tgcss-overflow-hidden tgcss-h-[54px] tgcss-w-[54px]">
                    <img class="tgcss-min-w-fit tgcss-w-fit tgcss-h-full" :src="item.image" :alt="item.title">
                </div>
            </router-link>
        </div>


    </div>
</template>
    
<script>
    export default {
        name: 'newsRow',
        props: ['cat', 'count', 'title', 'type'],
        components: {},
        data() {
            return {
                slider:[],
                slider_cat:0,
                loading:false
            }
        },
        mounted() {
            this.getSlider();
        },
        computed: {},
        methods: {
            isUpdatedLessThan24HoursAgo(updatedAt) {
                const updatedTimestamp = new Date(updatedAt).getTime() / 1000; // تبدیل تاریخ به تایم‌استمپ Unix
                const currentTimestamp = Date.now() / 1000; // تایم‌استمپ فعلی به ثانیه
                const oneDayInSeconds = 43200; // تعداد ثانیه‌ها در یک روز (24 ساعت)

                // بررسی اینکه آیا تاریخ وارد شده کمتر از 24 ساعت از تاریخ فعلی گذشته است
                return updatedTimestamp > currentTimestamp - oneDayInSeconds;
            },
            getSlider(){
                this.loading = true;
                $.ajax({
                    url: ( this.type == 'slug' ? 'https://api.tgju.org/v1/news/list?limit='+this.count+'&tag='+this.cat : 'https://api.tgju.org/v1/news/list?limit='+this.count+'&category_id='+this.cat ),
                    method: 'get',
                    complete: (res) => {
                        this.slider = res.responseJSON.response;  
                        this.slider_cat = Object.keys(this.slider.categories)[0];
                        this.loading = false;
                    }
                });
            },
        },
    }
</script>  